import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Services
import { HttpService } from '@shared/services/http.service';
import { AlertService } from '@shared/services/alert.service';

// Interfaces
import { IResponseTable } from '@shared/interface/response.interface';
import { ICompany } from '@shared/interface/company.interface';
import { LoadingService } from '@shared/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private path: any;

  public responseCompanies: IResponseTable<Array<ICompany>>;
  public companies: Array<ICompany>;
  public priorities: Array<number>;

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.responseCompanies = {
      total: 0,
      lista: [],
      paginaActual: 0,
      ultimaPagina: 0,
      elementosPagina: 0
    };

    this.companies = [];
    this.path = environment.api.company;
    this.priorities = Array.from(Array(10).keys()).map(index => ++index);
  }

  public getList(
    page: number,
    items: number,
    filter?: string
  ): Observable<IResponseTable<Array<ICompany>>> {
    const params = { pagina: page, limite: items };

    if (filter) {
      params['nombre[like]'] = `%${filter}%`;
    }

    return this.httpService.get<IResponseTable<Array<ICompany>>>(
      this.path.allCompaniesByPage,
      { params }
    );
  }

  public delete(id: number): Observable<string> {
    return this.httpService.delete<string>(`${this.path.company}/${id}`);
  }

  public registerCompany(body: any): void {
    this.loadingService.show();
    this.httpService
      .post<string>(`${this.path.company}`, body)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              this.router.navigateByUrl('/admin/marcas/compania').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public updateCompany(company: any, id: number): void {
    this.loadingService.show();
    this.httpService
      .put<string>(`${this.path.company}/${id}`, company)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              this.router.navigateByUrl('/admin/marcas/compania').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public getCompany(id: number): Observable<ICompany> {
    return this.httpService.get<ICompany>(`${this.path.company}/${id}`);
  }

  public getCompanies(): void {
    this.loadingService.show();
    this.httpService
      .get<Array<ICompany>>(`${this.path.allCompanies}`)
      .subscribe(response => {
        this.companies = response;
        this.loadingService.hide();
      });
  }
}
